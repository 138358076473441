import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class JanusStreamService {
  private janusUrl = `${environment.protocol}://${environment.janusIp}:${environment.janusHttpPort}/janus`;
  private sessionId: number | null = null;
  private handleId: number | null = null;
  session: any;
  pluginId: any;

  constructor(private http: HttpClient) { 
    
  }
  connectToEndPoint() {
    return new Promise((resolve, reject) => {
      this.createSession().subscribe((ev) => {
        this.session = (ev as any).data.id;
        this.attachPlugin(this.session).subscribe((ev1) => {
          this.pluginId = (ev1 as any).data.id;
          resolve("ok");
        }, (error) => reject("error attaching"))
      }, (error) => reject("error creating session"))
    })
  }
  // Método para iniciar sesión en Janus
  createSession(): Observable<any> {
    return this.http.post(this.janusUrl, { janus: "create", transaction: this.generateTransactionId() });
  }

  // Método para adjuntar el handle de streaming
  attachPlugin(sessionId: number): Observable<any> {
    return this.http.post(`${this.janusUrl}/${sessionId}`, {
      janus: "attach",
      plugin: "janus.plugin.streaming",
      transaction: this.generateTransactionId()
    });
  }

  // Método para añadir un nuevo stream
  addStream(name: string, streamId: number, port: number, audiopt: string):void {
    this.connectToEndPoint().then(() => {
      const body = {
        janus: 'message',
        transaction: this.generateTransactionId(),
        body: {
          request: 'create',
          id: streamId,
          type: 'rtp',
          description: name,
          video: false,
          audio: true,
          audioport: port,
          audiopt: parseInt(audiopt, 10),
          audiortpmap: 'opus/48000/2',
          permanent: true,
          secret:"adminpwd",
        }
      };
      this.http.post(`${this.janusUrl}/${this.session}/${this.pluginId}`, body).subscribe(ev => {
        console.log(ev);
      }, (error) => alert("error"))

    })

  }

  // Método para eliminar un stream
  removeStream(streamId: number): Observable<any> {
    if (!this.sessionId || !this.handleId) {
      return new Observable(observer => observer.error("Sesión o handle no inicializados"));
    }

    const body = {
      janus: 'message',
      transaction: this.generateTransactionId(),
      body: {
        request: 'destroy',
        id: streamId,
        permanent: true
      }
    };

    return this.http.post(`${this.janusUrl}/${this.sessionId}/${this.handleId}`, body);
  }

  // Método auxiliar para generar un transaction ID único
  private generateTransactionId(): string {
    return Math.random().toString(36).substring(2, 12);
  }
}
